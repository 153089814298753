import { CURRENCIES } from "./stores";

export enum KYC_STATUSES {
  IN_PROGRESS = "IN-PROGRESS",
  PENDING = "PENDING",
  DENIED = "DENIED",
  APPROVED = "APPROVED",
  NO_KYC = "NO_KYC",
}

export interface Wallets {
  kyc_status: KYC_STATUSES;
  wallets: Wallet[];
}

export interface Wallet {
  id: string;
  has_completed_kyc: boolean;
  currency: CURRENCIES;
  withdrawal_accounts: string[];
  balance: number;
  store: string;
  created_at: string;
  updated_at: string;
  accounts: AccountInformation[];
  limits?: {
    collection_limit?: number;
    daily_withdrawal_limit?: number;
  };
}

// export interface MonoAccountIds {
//   id: string;
//   account_holder_id: string;
//   sub_account_holder_id: string;
//   account_id: string;
//   alternate_account_id: string;
//   wallet: string;
// }
export interface AccountInformation {
  bank_code: string;
  bank_name: string;
  account_name: string;
  account_number: string;
  image?: string;
  provider?: string;
}

export interface WithdrawalAccount {
  recipient_id: string;
  account_name: string;
  account_number: string;
  bank_code: string;
  bank_name: string;
  wallet: string;
  id: string;
  image?: string;
}

export interface Bank {
  code: string;
  country: string;
  currency: string;
  id: number;
  name: string;
  slug: string;
  type: string;
  image?: string;
}

export interface Transaction {
  id: string;
  amount: number;
  type: "credit" | "debit";
  channel: "DIRECT TRANSFER" | "INVOICE PAYMENT";
  narration: string;
  balance_before: string;
  reference: string;
  wallet: string;
  created_at: string;
  updated_at: string;
  fee: number;
  source: {
    name?: string;
    account_number?: string;
    purpose?: string;
    method?: string;
  };
  currency: CURRENCIES;
}

export enum WITHDRAWAL_FEE_TYPES {
  "FIXED" = "FIXED",
  "PERCENTAGE" = "PERCENTAGE",
}

export interface Fees {
  fees: {
    floor: number;
    ceil: number;
    fee: number;
    range_desc: string;
    percentage?: number;
    fee_type: WITHDRAWAL_FEE_TYPES;
    fee_desc: string;
  }[];
  max_withdrawal_amount: number;
  min_withdrawal_amount: number;
}

export interface CatlogCredits {
  balance: number;
  owner: string;
  currency: CURRENCIES;
  id: string;
}

export interface CatlogCreditsTransaction {
  amount: number;
  narration: string;
  credit_wallet: string;
  currency: CURRENCIES;
  type: "credit" | "debit";
  id: string;
  created_at: string;
}
